import React from "react";
import "./VideoPlayer.css";

const VideoPlayer = ({ videoSrc }) => {
  return (
    <div className="video-container">
      <video className="video-player" src={videoSrc} autoPlay controls />
    </div>
  );
};

export default VideoPlayer;
