import React from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";
import videoData from "../data/videos"; // Import video data synchronously

const VideoPage = () => {
  const { videoId } = useParams(); // Get video ID from route

  // Find the video that matches the videoId
  const video = videoData.find((v) => v.id === videoId);

  // If video not found, display an error message
  if (!video) {
    return <h2 style={{ color: "red", textAlign: "center" }}>Video not found</h2>;
  }

  return (
    <div>
      <VideoPlayer videoSrc={video.src} />
    </div>
  );
};

export default VideoPage;
