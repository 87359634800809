import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoPage from "./pages/VideoPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route to dynamically load video pages */}
        <Route path="/:videoId" element={<VideoPage />} />

        {/* Default Route */}
        <Route
          path="*"
          element={<h1 style={{ textAlign: "center" }}>Welcome! Use /video1, /video2, etc.</h1>}
        />
      </Routes>
    </Router>
  );
}

export default App;
